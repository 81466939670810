<template>
  <div>
    <add-multiple-scooter />
    <add-single-scooter />

    <VehicleIndex
      :fst-id="fstId"
      :page-title="
        $t('components.orgUserDetailsManagement.diveIntoTab.vehicle.headline')
      "
      :endpoint="endpoint"
      :qso="qso"
      :filterItems="filterOptions"
      :tableHeaders="tableHeaders"
      @add:single="add('single')"
      @add:multiple="add('multiple')"
      @edit="edit"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleIndex } from '@/composites/vehicle'
export default {
  name: 'ViewOrganizationUserRentStationsVehicles',
  components: {
    AddSingleScooter: () => import('@/views/scooter/AddSingleScooter.vue'),
    AddMultipleScooter: () => import('@/views/scooter/AddMultipleScooter.vue'),
    VehicleIndex,
  },

  data() {
    return {
      fstId: null,
      is_edit: false,
      qso: { append: '', prepend: '' },
      endpoint: null,
      filterOptions: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.name'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.battery'
          ),
          width: '10%',
          sort: 'lock__power_level',
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.imei'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.qrCode'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.lastConnected'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.lastLocated'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.flags'
          ),
          width: '12%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.heartBeat'
          ),
          width: '8%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.lastLocation'
          ),
          width: '9%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.vehicle.table.columns.actions'
          ),
          width: '5%',
          sort: null,
        },
      ],
    }
  },
  async created() {
    this.fstId = `rent-stations-vehicles`
    this.generateEndPoint()
    await this.generateFilterOptions()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.vehicle.index() +
        `?rent_station_owner=${this.$route.params.id}&`
    },
    async generateFilterOptions() {
      const filterableFleets = []
      const url = useEndpoints.fleet.indexOfStaff(this.$route.params.id)
      await this.$http.get(url).then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({ text: fleet.name, value: fleet.id })
          })
        }
      })

      this.filterOptions = [
        {
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        },
        {
          key: 'bike_category',
          type: 'checkbox',
          input: [
            { text: 'Scooters', value: 'P' },
            { text: 'EBikes', value: 'E' },
            { text: 'Bikes', value: 'S' },
          ],
          title: 'Category',
        },
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Rent', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },

        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },

        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ]
    },
    add(type) {
      if (type == 'single') {
        EventBus.$emit(SingleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
      } else {
        EventBus.$emit(MultipleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
      }
      this.is_edit = false
      this.dropdownPopoverShow = false
    },

    edit({ vehicle }) {
      this.is_edit = true
      EventBus.$emit(SingleScooterConfig.events.editingData, vehicle)
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
  },
}
</script>
